import _ from "lodash"
import React from "react"
import Slider from "react-slick"
import "./CustomSlider.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
export const CustomSliderNew = ({
  children,
  className,
  slidecount,
  isQuote,
}) => {
  const options = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    // rtl: true,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 1.9,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 1.9,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 1.9,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots:true
        },
      },
    ],
  }

  return (
    <Slider className={`custom-slider ${className}`} {...options}>
      {children}
    </Slider>
  )
}

export default CustomSliderNew
