import React, { useState } from "react"
import CustomSliderNew from "../CustomSlider/CustomSliderNew"
import "./GallerySlider.scss"
import FsLightbox from "fslightbox-react"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import GGFXImage from "../../modules/GGFXImage"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const GallerySlider = props => {
  const [propertyImage, setPropertyImage] = useState(false)

  const imageList = props.galleryData?.gallery_image_list

  let galleryImage = []
  for (let i = 0; i < imageList.length; i++) {
    if (imageList[i]?.image?.url) {
      galleryImage.push(imageList[i].image?.url)
    }
  }

  const {isMobile}=useDeviceMedia()

  return (
    <section className="campaign-gallery-slider section_item" id="gallery-block">
      <CustomSliderNew className="campaign-slider" slidecount={2}>
        {imageList?.map((list, index) => {
          var imagename = "campign.image.gallery_image"

          let processedImages = JSON.stringify({})
          if (props?.galleryData?.imagetransforms) {
            processedImages = props?.galleryData?.imagetransforms?.image_Transforms
          }

          return (
            <div className="image-list_campaign">
              <GGFXImage
                ImageSrc={list?.image}
                altText={`${
                  list?.image?.alternativeText
                    ? list?.image?.alternativeText
                    : "Gallery Image"
                }`}
                imagetransforms={props?.galleryData?.ggfx_results}
                renderer="srcSet"
                imagename={imagename}
                strapiID={props?.galleryData?.strapi_id}
                classNames="gallery-img"
              />
              <div className="btn-gallery-bk">
                {imageList?.length > 0 && (
                  <button
                    className="button button-filled-white"
                    onClick={() => setPropertyImage(!propertyImage)}
                  >
                    <span>Open Gallery</span>
                    <i className="icon arrows-out-icon" />
                  </button>
                )}
              </div>

            
            </div>
          )
        })}
      </CustomSliderNew>

      {isMobile&&
               <button
               className="button button-filled-white mobile"
               onClick={() => setPropertyImage(!propertyImage)}
             >
               <span>Open Gallery</span>
               <i className="icon arrows-out-icon" />
             </button>
              }

      <FsLightbox toggler={propertyImage} sources={galleryImage} />
    </section>
  )
}

export default GallerySlider
