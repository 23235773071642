import React from "react"
import CustomSlider from "../CustomSlider/CustomSlider"
import { Container, Row, Col } from "react-bootstrap"
import "./CampignWithIcon.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import CustomSliderCampEvent from "../CustomSlider/CustomSliderCampEvent"
import ScrollAnimation from "react-animate-on-scroll"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const CampignWithIcon = props => {
  const {isMobile, isTabletOnly}=useDeviceMedia()
  return (
    <section className={`icon-campaign slider section_item`} id="about-block">
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="campaign-top-block">
              {props.title && <h2>{props.title}</h2>}

              {props.description && (
                <ContentModule Content={props.description?.data?.description || props.description} />
              )}
            </div>
          </Col>
        </Row>
      </Container>
      </ScrollAnimation>
      <div className="horizontal-line"></div>
      {props?.icon_module?.length > 0 ? (
        <Container>
          <Row>
            <Col lg={12}>
              <CustomSliderCampEvent
                slidecount={isMobile?"2":isTabletOnly?"3":props?.slide_count}
                className="campaign-icon-slider"
              >
                {props?.icon_module?.map((item, i) => {
                  var imagename = "campign.image.icon_image"

                  let processedImages = JSON.stringify({})
                  if (props?.imagetransforms) {
                    processedImages = props?.imagetransforms?.image_Transforms
                  }

                  return (
                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={i*100} offset={100}>
                    <div className="icon-item">
                      {/* <ImageModule
                        ImageSrc={item?.image}
                        altText={`${
                          item?.image?.alternativeText
                            ? item?.image?.alternativeText
                            : item.title
                            ? item.title
                            : ""
                        } icon`}
                        imagetransforms={processedImages}
                        renderer="pic-src"
                        imagename={imagename}
                        strapi_id={props?.id}
                        classNames="img-fluid banner-img"
                      /> */}
                      <img src={item?.image?.url} alt={item?.image?.alternativeText
                            ? item?.image?.alternativeText
                            : item.title}/>
                      <div className="title">{item?.title}</div>
                      {item.description && (
                        <ContentModule
                          Content={item.description?.data?.description || item?.description }
                        />
                      )}
                    </div>
                    </ScrollAnimation>
                  )
                })}
              </CustomSliderCampEvent>
            </Col>
          </Row>
        </Container>
      ) : null}
    </section>
  )
}

export default CampignWithIcon
