import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import "./CampaignPayment.scss"
import noImage from "../../images/no-image.png"
import GGFXImage from "../../modules/GGFXImage"


const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const CampaignPayment = props => {
  const { content, payment_items, title, payment_block_image } =
    props.paymentData

  var imagename = "campign.payment.payment_block_image"

  let processedImages = JSON.stringify({})
  if (props?.imagetransforms) {
    processedImages = props?.imagetransforms?.payment_Transforms
  }
  const dataLength = payment_items?.length
  return (
    <section
      className="campaign-payment-wrapper section_item"
      id="payment-block"
    >
      <ScrollAnimation
        animateIn="animate__slideInUp"
        animateOnce
        delay={100}
        offset={100}
      >
        <Container>
          <div className="payment-sections">
            <div className="image-section">
            {payment_block_image?.url?
              // <ImageModule
              //   ImageSrc={payment_block_image}
              //   altText={`${
              //     payment_block_image?.alternativeText
              //       ? payment_block_image?.alternativeText
              //       : title
              //       ? title
              //       : ""
              //   }`}
              //   imagetransforms={processedImages}
              //   renderer="pic-src"
              //   imagename={imagename}
              //   strapi_id={props?.id}
              //   classNames="img-fluid"
              // />
              <GGFXImage
                ImageSrc={payment_block_image}
                altText={`${
                  payment_block_image?.alternativeText
                  ? payment_block_image?.alternativeText
                  : title
                  ? title
                  : ""
              }`}
                  imagetransforms={props?.ggfx_results}
                  renderer="srcSet"
                  imagename={imagename}
                  strapiID={props?.id}
                />
              :
              <img classNames="img-fluid" src={noImage} alt="No Image" />
              }
            </div>
            <div className="payment-content">
              <h2 className="title">{title}</h2>
              {content&& (
                <p className="description">
                  <ContentModule Content={content?.data?.content || content} />
                </p>
              )}
              {payment_items?.length > 0 && (
                <div className="item-list">
                  {payment_items?.map((item, index) => {
                    return (
                      <div>
                        <div key={index} className="list">
                          <p>{item?.payment_text}</p>
                          <h5>{item?.payment_value}</h5>
                        </div>
                        {dataLength - 1 !== index && (
                          <div className="horizontal-line"></div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </Container>
      </ScrollAnimation>
    </section>
  )
}

export default CampaignPayment
